import { template as template_b16fb1041d3f4bb086a6d217b3ba2314 } from "@ember/template-compiler";
const WelcomeHeader = template_b16fb1041d3f4bb086a6d217b3ba2314(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
